import React, { Component } from 'react';
import { Question } from './Question';

import { ChevronDown } from './AdminComponents/ChevronDown';
import { ChevronLeft } from './AdminComponents/ChevronLeft';

import '../styles/Menu.css';

export class Dropdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            DropdownQuestions: []
        }
    }


    componentDidMount() {
        /* Hämtar in questions som tillhör respektive dropdownmeny*/
        fetch('https://sadima.lnu.se/api/getQuestion/' + this.props.itemID + '/1').then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                DropdownQuestions: data
            }));
    }

    render() {

        const { statement, shownDropdownId, handleDropdownSelection, itemID } = this.props;

        const isShowing = shownDropdownId === this.props.itemID;

        return (
            <div className={`dropdown-container ${isShowing && 'dropdown-container-showing'}`} style={{ overflowY: "scroll" }}>
                <div className="dropdown lnu-background" onClick={() => handleDropdownSelection(itemID)}>
                    {isShowing ? <ChevronDown /> : <ChevronLeft />}{statement}
                </div>
                {isShowing &&
                    <div className="dropdown-questions">
                    {this.state.DropdownQuestions.map((props) => (
                        <Question
                            question={props.textUrl}
                            itemID={props.id}
                            audio={props.audioUrl}
                            video={props.videoUrl}
                            onAnswerChange={this.props.onAnswerChange}
                        ></Question>
                    ))}
                    </div>
                }
             </div>
              
            

        );
    }

}
