import React, { Component } from 'react';

export class QuestionForm extends Component {

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {

        event.preventDefault();
        const data = new FormData(event.target);
        var questiontype = data.get('questiontype');

        this.props.onSubmit(data, this.props.itemID, questiontype);
    }




    render() {

        const langs = this.props.languages;

        return (
            <div>
                <form id="form" onSubmit={this.handleSubmit} method="post" style={{ width: "500px" }}>
                    <div className="form-group">

                        <input name="questiontype" type="text" className="form-control" id="questiontype" placeholder="Vad är det för frågetyp?" />

                        {langs.map((props) => (
                            <div style={{ border: "1px solid lightgrey", fontFamily: "Arial" }}>
                                <label style={{ fontSize: "30px" }}> {props.languageText} </label>
                                <input type="number" name={"questionList[" + (props.id - 1) + "].LanguageId"} value={props.id} readOnly style={{ display: "none" }} />
                                <input name={"questionList[" + (props.id - 1) + "].TextUrl"} type="text" className="form-control" id="textUrl" placeholder= "Text" style={{border: "0px solid"}} />

                                <input name={"questionList[" + (props.id - 1) + "].AudioFile"} type="file" className="form-control" id="audioUrl" placeholder= "Ljudfil" style={{ border: "0px solid" }}/>

                                <input name={"questionList[" + (props.id - 1) + "].VideoFile"} type="file" className="form-control" id="videoUrl" placeholder= "Videofil" style={{ border: "0px solid" }}/>

                            </div>
                        ))}
                    </div>
                    <button type="submit" className="btn btn-primary">Skapa</button>
                </form>



            </div>

        );
    }

}
