import React, { Component } from 'react';
import { ChevronLeft } from './AdminComponents/ChevronLeft';

export class InformationItem extends Component {

    constructor(props) {
        super(props);
        this.questionChange = this.questionChange.bind(this);

    }

    questionChange() {
        this.props.onQuestionChange(this.props.itemID, this.props.statement);
    }

     

    render() {

        const statement = this.props.statement;

        return (
            <div className="nav-link Statements" id={this.props.itemID} onClick={this.questionChange}>{statement} <ChevronLeft /></div>
        );
    }

}
