import React, { Component } from 'react';
import { Button, Tooltip } from 'react-bootstrap';
import { ButtonToolbar } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { AdminInformationItem } from './AdminComponents/AdminInformationItem';
import { AdminQuestion } from './AdminComponents/AdminQuestion';
import { AdminDropdown } from './AdminComponents/AdminDropdown';
import { MenuForm } from './AdminComponents/Forms/MenuForm';

import '../styles/Menu.css';
import "react-datepicker/dist/react-datepicker.css";
import { AdminLoginScreen } from './AdminComponents/AdminLoginScreen';
import { TrashIcon } from './AdminComponents/TrashIcon';
import { CreateIcon } from './AdminComponents/CreateIcon';
import { QuestionForm } from './AdminComponents/Forms/QuestionForm';
import { AnswerForm } from './AdminComponents/Forms/AnswerForm';


export class AdminView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            questionToShow: 1000,
            answerToShow: 1000,
            shownDropdownId: null,
            displayLanguage: "Svenska",
            LanguageID: 1,
            displayHeader: "Sadima",
            MenuItems: [],
            SwedishQuestionItems: [],
            Languages: [],
            TranslationToShow: [],
            QuestionType: "PXQEmpty",
            ChildrenToChosenMenu: [],
            SweAnswerItems: [],
            ArabAnswerItems: [],
            AnswerItems: [],
            AudioToBePlayed: new Audio(""),
            AudioHasEnded: false,
            VideoToBePlayed: "",
            startDate: new Date(),
            loggedIn: localStorage.getItem('userAuthenticated'),
            showCreateMenuForm: false,
            showCreateQuestionForm: false,
            showCreateAnswerForm: false,
            parentID: null,
            someState: 1,
            SweCountries: [],
            ArabCountries: [],
            SweSpokenLanguages: [],
            ArabSpokenLanguages: [],
            baseUrl: "https://sadima.lnu.se/api/"
        };

        this.handleQuestionChange = this.handleQuestionChange.bind(this);
        this.handleAnswerChange = this.handleAnswerChange.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.handleDropdownSelection = this.handleDropdownSelection.bind(this);
        this.handleMenuDelete = this.handleMenuDelete.bind(this);
        this.handleCreateMenu = this.handleCreateMenu.bind(this);
        this.handleQuestionDelete = this.handleQuestionDelete.bind(this);
        this.handleQuestionCreate = this.handleQuestionCreate.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleMenuSubmit = this.handleMenuSubmit.bind(this);
        this.handleQuestionSubmit = this.handleQuestionSubmit.bind(this);
        this.handleAnswerCreate = this.handleAnswerCreate.bind(this);
        this.handleAnswerSubmit = this.handleAnswerSubmit.bind(this);
    }


    /* Hanterar state för languagechange och gör det möjligt att ha olika svar-värden beroende på vilket språk som är satt */
    async handleLanguageChange() {

        console.log(this.state.displayLanguage);

        var temp = this.state.displayLanguage;

        await this.state.Languages.map((props) => {

            if (props.id === this.state.LanguageID + 1) {

                this.setState({
                    ...this.state,
                    displayLanguage: props.languageText,
                    LanguageID: props.id
                })
            }

        });

        if (temp === this.state.displayLanguage) {

            this.setState({
                ...this.state,
                displayLanguage: "Svenska",
                LanguageID: 1
            });
        }

        //this.state.displayLanguage === "Svenska" ? await this.setState({ ...this.state, displayLanguage: "Arabiska", LanguageID: 2 }) : await this.setState({ ...this.state, displayLanguage: "Svenska", LanguageID: 1 });

        await fetch(this.state.baseUrl + 'GetQuestionOnQuestionAndLanguageID/' + this.state.answerToShow + '/' + this.state.LanguageID).then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                TranslationToShow: data
            }));
    }

    /* Hanterar vilket svar vi ska visa baserat på ID:t av elementet vi har klickat på, samt sätter antingen ljud eller video baserat på QuestionType */
    async handleAnswerChange(id, audioUrl, videoUrl) {

        this.state.AudioToBePlayed.pause();

        await fetch(this.state.baseUrl + 'GetQuestionTypeOnQuestionID/' + id).then(response => response.text()).then(data =>
            this.setState({
                ...this.state,
                QuestionType: data
            }));

        this.setState({
            ...this.state,
            answerToShow: id,
            AudioToBePlayed: new Audio(),
            AudioHasEnded: false
        });

        await fetch(this.state.baseUrl + 'GetQuestionOnQuestionAndLanguageID/' + id + '/' + this.state.LanguageID).then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                QuestionObject: data
            }));

        this.state.AudioToBePlayed.src = this.state.QuestionObject[0].audioUrl;

        if (this.state.QuestionType !== "PXQVideo") {

            var audio = document.getElementById("audio");
            audio.src = this.state.QuestionObject[0].audioUrl;

            var audioPromise = audio.play();

            if (audioPromise !== undefined) {
                audioPromise.then(x => {
                    console.log("audio played auto");
                })
                    .catch(error => {
                        console.log("didnt play audio");
                    });
            }

            audio.onended = () => {
                this.setState({ ...this.state, AudioHasEnded: true, AudioToBePlayed: new Audio() })
            }
        }
        else {

            this.setState({
                ...this.state,
                VideoToBePlayed: videoUrl
            })

            var video = document.getElementById("video");
            video.src = this.state.QuestionObject[0].videoUrl;
        }

        fetch(this.state.baseUrl + 'GetQuestionOnQuestionAndLanguageID/' + id + '/' + this.state.LanguageID).then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                TranslationToShow: data
            }));

        await fetch(this.state.baseUrl + 'GetAllAnswersOnQuestionAndLanguageID/' + id + '/' + this.state.LanguageID).then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                SweAnswerItems: data
            }));

        await fetch(this.state.baseUrl + 'GetAllAnswersOnQuestionAndLanguageID/' + id + '/' + 2).then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                ArabAnswerItems: data
            }));

        var temp = [];

        for (var i = 0; i < this.state.SweAnswerItems.length; i++) {
            temp.push({
                "swe": this.state.SweAnswerItems[i],
                "arab": this.state.ArabAnswerItems[i]
            })
        }

        await this.setState({
            ...this.state,
            AnswerItems: temp
        });
    }

    /* Hanterar vilken fråga samt header vi ska visa baserat på vilket menyval som har klickats på, återställer också svaren om vi byter meny */
    handleQuestionChange(id, statement) {

        if (document.getElementById(this.state.questionToShow) !== null) {
            document.getElementById(this.state.questionToShow).style.backgroundColor = 'white';
            document.getElementById(this.state.questionToShow).style.color = 'black';
        }

        this.setState({
            ...this.state,
            questionToShow: id,
            parentID: id,
            answerToShow: 1000,
            displayHeader: statement,
            TranslationToShow: [],
            QuestionType: "",
            AnswerItems: [],
            ChildrenToChosenMenu: []
        });

        fetch(this.state.baseUrl + 'getQuestion/' + id + '/1').then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                SwedishQuestionItems: data
            }));

        fetch(this.state.baseUrl + 'getChildren/' + id).then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                ChildrenToChosenMenu: data
            }));

        if (this.state.ChildrenToChosenMenu.length !== 0) {
            this.setState({
                dropDownParent: id
            });
        }

        document.getElementById(id).style.backgroundColor = 'black';
        document.getElementById(id).style.color = 'white';

    }

    /* Hanterar val av dropdown. Den valda dropdownens children visas. Alla andra dropdowns children döljs. */
    handleDropdownSelection(id) {

        this.setState({
            ...this.state,
            shownDropdownId: id,
            answerToShow: 1000,
            TranslationToShow: [],
            AnswerItems: [],
            parentID: id
        });

    }

    /* Sätter states för att lägga till meny, sätter state för att öppna menyformuläret*/
    handleCreateMenu(id) {

        this.setState({
            ...this.state,
            parentID: id,
            showCreateMenuForm: true,
            showCreateQuestionForm: false,
            showCreateAnswerForm: false
        })

    }

    /* Skickar DELETE request till backenden och tar bord valda meny, hämtar också hem menyer och children för att det ska reflekteras direkt på sidan*/
    async handleMenuDelete(id) {

        await fetch(this.state.baseUrl + 'DeleteMenuItem/' + id, { method: 'DELETE' });

        await this.setState({
            ...this.state,
            someState: 5
        });

        await fetch(this.state.baseUrl + 'getChildren/' + this.state.questionToShow).then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                ChildrenToChosenMenu: data
            }));

        await fetch(this.state.baseUrl + 'GetMenus').then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                MenuItems: data
            }))
    }

    /* Sätter states för att skapa en fråga, sätter state för att öppna frågeformuläret */
    handleQuestionCreate(id) {

        this.setState({
            ...this.state,
            parentID: id,
            showCreateQuestionForm: true,
            showCreateMenuForm: false,
            showCreateAnswerForm: false
        })

        this.state.showCreateQuestionForm = true; //Vet inte varför detta fungerar men det gör det (fixa sen)
    }

    /* Sätter states för att skapa svarsalternativ, sätter state för att öppna svarsformuläret */
    handleAnswerCreate(id) {

        this.setState({
            ...this.state,
            parentID: id,
            showCreateAnswerForm: true,
            showCreateQuestionForm: false,
            showCreateMenuForm: false
        })

    }

    /* Skickar DELETE request till backenden och tar bord valda fråga, hämtar också hem frågor och children (ifall det är en dropdown) för att det ska reflekteras direkt på sidan*/
    async handleQuestionDelete(id) {

        await fetch(this.state.baseUrl + 'DeleteQuestion/' + id, { method: 'DELETE' });

        await this.setState({
            ...this.state,
            ChildrenToChosenMenu: []
        })

        await fetch(this.state.baseUrl + 'getChildren/' + this.state.questionToShow).then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                ChildrenToChosenMenu: data
            }));

        await fetch(this.state.baseUrl + 'getQuestion/' + this.state.questionToShow + '/1').then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                SwedishQuestionItems: data
            }));

    }

    /* Kollar om användaren är inloggad eller inte och sätter state */
    handleLogout() {
        localStorage.setItem('userAuthenticated', 'false');

        this.setState({
            ...this.state,
            loggedIn: false
        })

    }


    /* Skickar POST request till backenden och skapar en ny meny, hämtar också hem menyer och children för att det ska reflekteras direkt på sidan*/
    async handleMenuSubmit(data, id) {

        await fetch(this.state.baseUrl + 'CreateMenuItem/0/' + id, {
            method: 'POST',
            body: data
        }).then(function () {
            console.log("ok");
        }).catch(function () {
            console.log("error")
        });

        await this.setState({
            showCreateMenuForm: false,
            ChildrenToChosenMenu: []
        });

        await fetch(this.state.baseUrl + 'getChildren/' + this.state.questionToShow).then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                ChildrenToChosenMenu: data
            }));

        await fetch(this.state.baseUrl + 'GetMenus').then(response => response.json()).then(data =>
            this.setState({
                MenuItems: data
            }));
    }

    /* Skickar POST request till backenden och skapar en ny fråga, hämtar också hem frågor och children (om det är en dropdown) för att det ska reflekteras direkt på sidan*/
    async handleQuestionSubmit(data, QuestionType) {

        await fetch(this.state.baseUrl + 'CreateQuestion/0/' + this.state.parentID + '/' + QuestionType, {
            method: 'POST',
            body: data
        }).then(function () {
            console.log("ok");
        }).catch(function () {
            console.log("error")
        });

        await this.setState({
            ...this.state,
            showCreateQuestionForm: false,
            ChildrenToChosenMenu: []
        })

        await fetch(this.state.baseUrl + 'getChildren/' + this.state.questionToShow).then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                ChildrenToChosenMenu: data
            }));

        await fetch(this.state.baseUrl + 'getQuestion/' + this.state.parentID + '/1').then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                SwedishQuestionItems: data
            }));

    }


    /* Skickar POST request till backenden och skapar svarsalternativ, hämtar också svarsalternativ från databasen för att det ska reflekteras direkt på sidan*/
    async handleAnswerSubmit(data, id) {

        await fetch(this.state.baseUrl + 'CreateAnswer/0/' + id, {
            method: 'POST',
            body: data
        }).then(function () {
            console.log("ok");
        }).catch(function () {
            console.log("error")
        });

        this.setState({
            ...this.state,
            showCreateAnswerForm: false
        });

        await fetch(this.state.baseUrl + 'GetAllAnswersOnQuestionAndLanguageID/' + id + '/' + this.state.LanguageID).then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                SweAnswerItems: data
            }));

        await fetch(this.state.baseUrl + 'GetAllAnswersOnQuestionAndLanguageID/' + id + '/' + 2).then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                ArabAnswerItems: data
            }));

        var temp = [];

        for (var i = 0; i < this.state.SweAnswerItems.length; i++) {
            temp.push({
                "swe": this.state.SweAnswerItems[i],
                "arab": this.state.ArabAnswerItems[i]
            })
        }

        await this.setState({
            ...this.state,
            AnswerItems: temp
        });
    }

    /* Hämtar hem menyval och språk när man laddar sidan */
    componentDidMount() {

        fetch(this.state.baseUrl + 'GetMenus').then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                MenuItems: data
            }));

        fetch(this.state.baseUrl + 'GetAllLanguages').then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                Languages: data
            }));

        fetch(this.state.baseUrl + 'GetAllCountriesOnLanguageID/1').then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                SweCountries: data
            }));

        fetch(this.state.baseUrl + 'GetAllCountriesOnLanguageID/2').then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                ArabCountries: data
            }));

        fetch(this.state.baseUrl + 'GetAllLanguagesOnLanguageID/1').then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                SweSpokenLanguages: data
            }));

        fetch(this.state.baseUrl + 'GetAllLanguagesOnLanguageID/2').then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                ArabSpokenLanguages: data
            }));

    }


    render() {

        const isDropdownMenu = this.state.ChildrenToChosenMenu.length !== 0;
        const isVideo = this.state.QuestionType === "PXQVideo";
        const isMultiChoice = this.state.QuestionType === "PXQMultiButton"; // Skillnad på MultiChoice och MultiSelect är att på multiselect ska man kunna markera fler än 1 val.
        const isMultiSelect = this.state.QuestionType === "PXQMultiSelect";
        const isInput = this.state.QuestionType === "PXQLetters" || this.state.QuestionType === "PXQNumbers" || this.state.QuestionType === "PXQYears";
        const isCalendar = this.state.QuestionType === "PXQCalendar";
        const isYesNo = this.state.QuestionType === "PXQYesNo";
        const isCountries = this.state.QuestionType === "PXQCountries";
        const isLanguages = this.state.QuestionType === "PXQLanguages";
        const isYesNoCountries = this.state.QuestionType === "PXQYesNoCountries";
        const isYesNoMulti = this.state.QuestionType === "PXQYesNoMulti";
        const isEmpty = this.state.QuestionType === "PXQEmpty";


        return (

            <div className="sadima">

                {this.state.showCreateMenuForm ? <MenuForm itemID={this.state.parentID} onSubmit={this.handleMenuSubmit} /> : <></>}
                {this.state.showCreateQuestionForm ? <QuestionForm itemID={this.state.parentID} languages={this.state.Languages} onSubmit={this.handleQuestionSubmit} /> : <></>}
                {this.state.showCreateAnswerForm ? <AnswerForm itemID={this.state.answerToShow} languages={this.state.Languages} onSubmit={this.handleAnswerSubmit} /> : <></>}

                {this.state.loggedIn === 'true' ?

                    <div>

                        <div className="row no-gutters grey-background small-height">
                            <div className="sadimatext col-2" style={{ float: "right" }}>
                                ADMIN
                                <button type="button" className="btn btn-default btn-sm" style={{ float: "right" }} onClick={() => this.handleCreateMenu(null)} title="Skapa en Huvudmeny">
                                    <CreateIcon />
                                    <audio id="audio" className="audio-container" src="" />
                                </button>
                            </div>

                            <div className="col-10">
                                <div className="right-header">
                                    <div className="right-header-text">{this.state.displayHeader}

                                        <Button className="multichoice-btn" variant="outline-secondary" size="sm" style={{ float: "right" }} onClick={this.handleLogout}> Logga ut </Button>

                                        {this.state.questionToShow !== 1000 ?
                                            <button type="button" className="btn btn-default btn-sm" style={{ float: "right" }} onClick={() => this.handleCreateMenu(this.state.questionToShow)} title="Skapa en dropdown meny">
                                                <CreateIcon />
                                            </button>
                                            :
                                            <></>
                                        }

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div id="menu" className="menu">
                            <div className="static-statements lnu-background" style={{ display: "flex" }}>
                                Patientens språk <div className="chooseLanguageText" onClick={this.handleLanguageChange}>{this.state.displayLanguage} </div>
                            </div>

                            {this.state.MenuItems.map((props) => (
                                <div>
                                    <div className="static-statements grey-background" >
                                        {props.menuText}
                                        <button type="button" className="btn btn-default btn-sm" style={{ float: "right" }} onClick={() => { if (window.confirm("Är du säker på att du vill ta bort menyn och allt under denna?")) this.handleMenuDelete(props.id) }} title="Ta bort huvudmeny">
                                            <TrashIcon />
                                        </button>
                                        <button type="button" className="btn btn-default btn-sm" style={{ float: "right" }} onClick={() => this.handleCreateMenu(props.id)} title="Skapa undermeny">
                                            <CreateIcon />
                                        </button>
                                    </div>

                                    {props.menuChildren && props.menuChildren.map((props) => (
                                        <AdminInformationItem
                                            statement={props.menuText}
                                            itemID={props.id}
                                            onQuestionChange={this.handleQuestionChange}
                                            onDelete={this.handleMenuDelete}
                                            onCreate={this.handleQuestionCreate}
                                        ></AdminInformationItem>
                                    ))}
                                </div>
                            ))}

                        </div>

                        <div id="main" className="main">
                            <div className="row-5 half-window make-scrollable add-border goback">
                                {isDropdownMenu ?
                                    <div>
                                        {this.state.ChildrenToChosenMenu.map((props) => (
                                            <AdminDropdown
                                                shownDropdownId={this.state.shownDropdownId}
                                                handleDropdownSelection={this.handleDropdownSelection}
                                                statement={props.menuText}
                                                itemID={props.id}
                                                onAnswerChange={this.handleAnswerChange}
                                                onCreate={this.handleQuestionCreate}
                                                onDelete={this.handleMenuDelete}
                                                onQuestionDelete={this.handleQuestionDelete}
                                            ></AdminDropdown>
                                        ))}
                                    </div>
                                    :
                                    <div>
                                        {this.state.SwedishQuestionItems.map((props) => (
                                            <AdminQuestion
                                                itemID={props.id}
                                                question={props.textUrl}
                                                audio={props.audioUrl}
                                                video={props.videoUrl}
                                                onAnswerChange={this.handleAnswerChange}
                                                onDelete={this.handleQuestionDelete}
                                                onCreate={this.handleAnswerCreate}
                                            ></AdminQuestion>
                                        ))}

                                    </div>}

                            </div>

                            <div className={`tranwindow row half-window make-scrollable ${isVideo && 'content-center'}`}>

                                {isVideo ?
                                    <div>
                                        <video id="video" controls autoPlay className="video-container" src="" >
                                        </video>
                                    </div>
                                    :
                                    <></>
                                }

                                {isMultiChoice ?
                                    <div className="col-6 answerbox">
                                        {this.state.AnswerItems.length === 0 ?
                                            <div className="create-answer-container">
                                                <button type="button" className="btn btn-default btn-sm" style={{ float: "right" }} onClick={this.handleAnswerCreate} title="Skapa svarsalternativ">
                                                    <CreateIcon />
                                                </button>
                                            </div>
                                            :
                                            <div>
                                                {this.state.AudioHasEnded ?
                                                    <div className="answer-container-show">
                                                        <div className="create-answer-container">
                                                            <button type="button" className="btn btn-default btn-sm" style={{ float: "right" }} onClick={this.handleAnswerCreate} title="Lägg till svarsalternativ">
                                                                <CreateIcon />
                                                            </button>
                                                        </div>
                                                        <div className="make-scrollable">
                                                            {this.state.AnswerItems.map((props) => (
                                                                <Button className="multichoice-btn" variant="outline-secondary" size="lg"> {props.swe.answerText} {props.arab.answerText} </Button>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-6 answerbox"></div>
                                                }
                                            </div>}
                                    </div>
                                    :
                                    <></>
                                }

                                {isMultiSelect ?
                                    <div className="col-6 answerbox">
                                        {this.state.AnswerItems.length === 0 ?
                                            <div className="create-answer-container">
                                                <button type="button" className="btn btn-default btn-sm" style={{ float: "right" }} onClick={this.handleAnswerCreate} title="Skapa svarsalternativ">
                                                    <CreateIcon />
                                                </button>
                                            </div>
                                            :
                                            <div>
                                                {this.state.AudioHasEnded ?
                                                    <div className="answer-container-show">
                                                        <div className="create-answer-container">
                                                            <button type="button" className="btn btn-default btn-sm" style={{ float: "right" }} onClick={this.handleAnswerCreate} title="Lägg till svarsalternativ">
                                                                <CreateIcon />
                                                            </button>
                                                        </div>
                                                        <div className="make-scrollable">
                                                            {this.state.AnswerItems.map((props) => (
                                                                <Button className="multichoice-btn" variant="outline-secondary" size="lg"> {props.swe.answerText} {props.arab.answerText}</Button>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-6 answerbox"></div>
                                                }
                                            </div>}
                                    </div>
                                    :
                                    <></>
                                }

                                {isCalendar ?
                                    <div className="col-6 answerbox">

                                        {this.state.AudioHasEnded ?

                                            <DatePicker selected={this.state.startDate} onChange={date => this.setState({ startDate: date })} />

                                            :
                                            <div className="col-6 answerbox"></div>
                                        }
                                    </div>
                                    :
                                    <></>
                                }

                                {isInput ?
                                    <div className="col-6 answerbox">

                                        {this.state.AudioHasEnded ?

                                            <input autoFocus type="text" />

                                            :
                                            <div className="col-6 answerbox"></div>
                                        }
                                    </div>
                                    :
                                    <></>
                                }

                                {isYesNo ?
                                    <div className="col-6 answerbox">

                                        {this.state.AudioHasEnded ?

                                            <ButtonToolbar className="yesno">

                                                <Button className="yesno d-flex justify-content-center d-md-table mx-auto" variant="outline-success" size="lg"> Ja </Button>

                                                <Button className="yesno d-flex justify-content-center d-md-table mx-auto" variant="outline-danger" size="lg"> Nej </Button>

                                            </ButtonToolbar>

                                            :
                                            <div className="col-6 answerbox"></div>
                                        }
                                    </div>
                                    :
                                    <></>
                                }

                                {isYesNoCountries ?
                                    <div className="col-6 answerbox">

                                        {this.state.AudioHasEnded ?
                                            <div>
                                                {this.state.LanguageID === 1 ?
                                                    <div>
                                                        <ButtonToolbar className="yesno">

                                                            <Button className="yesno d-flex justify-content-center d-md-table mx-auto" variant="outline-success" size="lg"> Ja </Button>

                                                            <Button className="yesno d-flex justify-content-center d-md-table mx-auto" variant="outline-danger" size="lg"> Nej </Button>

                                                        </ButtonToolbar>
                                                        <select>
                                                            {this.state.SweCountries.map((props) => (
                                                                <option value={props.countryName}>{props.countryName}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    :
                                                    <select>
                                                        {this.state.ArabCountries.map((props) => (
                                                            <option value={props.countryName}>{props.countryName}</option>
                                                        ))}
                                                    </select>
                                                }
                                            </div>
                                            :
                                            <div className="col-6 answerbox"></div>
                                        }
                                    </div>
                                    :
                                    <></>
                                }

                                {isYesNoMulti ?
                                    <div className="col-6 answerbox">

                                        {this.state.AudioHasEnded ?
                                            <div>
                                                <ButtonToolbar className="yesno">

                                                    <Button className="yesno d-flex justify-content-center d-md-table mx-auto" variant="outline-success" size="lg"> Ja </Button>

                                                    <Button className="yesno d-flex justify-content-center d-md-table mx-auto" variant="outline-danger" size="lg"> Nej </Button>

                                                </ButtonToolbar>
                                                {this.state.AnswerItems.map((props) => (
                                                    <Button className="multichoice-btn" variant="outline-secondary" size="lg" onClick={() => this.handlePlaySound(props.swe.answerAudio, props.arab.answerAudio)} ><div><div className="col">{props.swe.answerText}</div> <div className="col">{props.arab.answerText}</div> </div></Button>
                                                ))}

                                            </div>
                                            :
                                            <div className="col-6 answerbox"></div>
                                        }
                                    </div>
                                    :
                                    <></>
                                }

                                {isCountries ?
                                    <div className="col-6 answerbox">

                                        {this.state.AudioHasEnded ?
                                            <div>
                                                {this.state.LanguageID === 1 ?
                                                    <select>
                                                        {this.state.SweCountries.map((props) => (
                                                            <option value={props.countryName}>{props.countryName}</option>
                                                        ))}
                                                    </select>
                                                    :
                                                    <select>
                                                        {this.state.ArabCountries.map((props) => (
                                                            <option value={props.countryName}>{props.countryName}</option>
                                                        ))}
                                                    </select>
                                                }
                                            </div>
                                            :
                                            <div className="col-6 answerbox"></div>
                                        }
                                    </div>
                                    :
                                    <></>
                                }

                                {isLanguages ?
                                    <div className="col-6 answerbox">

                                        {this.state.AudioHasEnded ?
                                            <div>
                                                {this.state.LanguageID === 1 ?
                                                    <select>
                                                        {this.state.SweSpokenLanguages.map((props) => (
                                                            <option value={props.spokenLanguageText}>{props.spokenLanguageText}</option>
                                                        ))}
                                                    </select>
                                                    :
                                                    <select>
                                                        {this.state.ArabSpokenLanguages.map((props) => (
                                                            <option value={props.spokenLanguageText}>{props.spokenLanguageText}</option>
                                                        ))}
                                                    </select>
                                                }
                                            </div>
                                            :
                                            <div className="col-6 answerbox"></div>
                                        }
                                    </div>
                                    :
                                    <></>
                                }

                                {isEmpty ?
                                    <div className="col-6 answerbox">

                                    </div>
                                    :
                                    <></>
                                }


                                {isVideo ?
                                    <></>
                                    :
                                    <div className="col-6 answerbox">
                                        {this.state.TranslationToShow.map((props) => (
                                            <div className="answer">
                                                {props.textUrl}
                                            </div>
                                        ))
                                        }
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                    :
                    <AdminLoginScreen />
                }
            </div>
        );
    }
}
