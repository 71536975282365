import React, { Component } from 'react';
import { AdminView } from '../AdminView';

export class AdminLoginScreen extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            credentials: [],
            userAccepted: localStorage.getItem('userAuthenticated')
        }


    }

    handleSubmit(event) {
        const data = new FormData(event.target);

        var inputUsername = data.get('username');
        var inputPassword = data.get('password');

        this.state.credentials.map((props) => {

            if (props.username === inputUsername && props.password === inputPassword) {
                this.setState({
                    ...this.state,
                    userAccepted: 'true'
                }, localStorage.setItem('userAuthenticated', 'true'))
            }
        });
        console.log(this.state.userAccepted);

        

    }

    componentDidMount() {

        this.setState({
            ...this.state,
            userAccepted: localStorage.getItem('userAuthenticated')
        })
        
        console.log("LS värde: " + localStorage.getItem('userAuthenticated'));
        console.log(this.state.userAccepted);

        fetch('https://sadima.lnu.se/api/User/api/GetUserCredentials').then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                credentials: data
            }));
    }

    render() {


        return (
            <div>
                {this.state.userAccepted === 'true' ?
                    <AdminView />
                    :
                    <div>
                        <form onSubmit={this.handleSubmit} method="post" style={{ width: "400px", margin: "0 auto", paddingTop: "30px" }}>
                        <div className="form-group" >
                            <label>Användarnamn</label>
                            <input name="username" type="text" className="form-control" id="username" aria-describedby="emailHelp" placeholder="Användarnamn" />
                        </div>
                        <div className="form-group">
                            <label>Lösenord</label>
                            <input name="password" type="password" className="form-control" id="password" placeholder="Lösenord" />
                        </div>
                        <button type="submit" className="btn btn-primary">Logga in</button>
                        </form>
                    </div>
                }

            </div>

        );
    }

}
