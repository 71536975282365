import React, { Component } from 'react';

export class AnswerForm extends Component {

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCount = this.handleCount.bind(this);
        this.state = {
            numberOfItems: 0,
            items: [],
            showInputform: true
        }
    }

    handleSubmit(event) {

        event.preventDefault();
        const data = new FormData(event.target);

        this.props.onSubmit(data, this.props.itemID);
    }



    handleCount(event) {
        event.preventDefault();
        const data = new FormData(event.target);

        console.log(data.get('alternativ'));

        this.state.items = [];

        for (let i = 0; i < data.get('alternativ'); i++) {
            this.setState({
                ...this.state,
                items: this.state.items.push({ "Nummer" : i })
            });
            console.log("lagt till");
        }

        this.setState({
            ...this.state,
            numberOfItems: data.get('alternativ'),
            showInputform: true
        })
    }



    render() {

        const itemsToMap = this.state.items;
        const showInput = this.state.showInputform;
        const languages = this.props.languages;

        return (
            <div>
                

                {showInput ?
                    <form onSubmit={this.handleSubmit} method="post" style={{ width: "500px" }}>
                        <div className="form-group">

                           
                            {languages.map((props) => (
                                <div>
                                    <label>{props.languageText}</label>
                                        <div style={{ border: "1px solid lightgrey", fontFamily: "Arial" }}>
                                            <input type="number" name={"answerList[" + (props.id - 1) + "].LanguageId"} value={props.id} readOnly style={{ display: "none" }} />
                                            <input name={"answerList[" + (props.id - 1) + "].AnswerText"} type="text" className="form-control" id="alternativ" placeholder="Vad är alternativet?" />
                                        <input name={"answerList[" + (props.id - 1) + "].AudioFile"} type="file" className="form-control" id="audioUrl" placeholder="Ljudfil" style={{ border: "0px solid" }} />
                                        </div>
                                            
                                </div>
                                ))}
                            

                        </div>
                        <button type="submit" className="btn btn-primary">Skapa</button>
                    </form>
                    :
                    <></>}


            </div>

        );
    }

}
