import React, { Component } from 'react';
import { TrashIcon } from './TrashIcon';
import { CreateIcon } from './CreateIcon';

export class AdminInformationItem extends Component {

    constructor(props) {
        super(props);
        this.questionChange = this.questionChange.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.createItem = this.createItem.bind(this);

    }

    questionChange() {
        this.props.onQuestionChange(this.props.itemID, this.props.statement);
    }

    deleteItem() {
        console.log("Click!");
        this.props.onDelete(this.props.itemID);
    }

    createItem() {
        console.log("Click!");
        this.props.onCreate(this.props.itemID);
    }

     

    render() {

        const statement = this.props.statement;

        return (
            
            <div className="Statements" id={this.props.itemID} onClick={this.questionChange}>
                {statement}
                <button type="button" className="btn btn-default btn-sm" style={{ float: "right" }} onClick={() => { if (window.confirm("Är du säker på att du vill ta bort denna menyn och allt under denna?")) this.deleteItem(this.props.itemID) }} title="Ta bort undermeny">
                    <TrashIcon />
                </button>
                <button type="button" className="btn btn-default btn-sm" style={{ float: "right" }} onClick={() => this.createItem(this.props.itemID)} title="Skapa en fråga">
                    <CreateIcon />
                </button>
            </div>
            
                
        );
    }

}
