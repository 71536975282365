import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { ButtonToolbar } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { LeftHeader } from './LeftHeader';
import { RightHeader } from './RightHeader';
import { InformationItem } from './InformationItem';
import { Question } from './Question';
import { Dropdown } from './Dropdown';
import { ChevronLeft } from './AdminComponents/ChevronLeft';

import '../styles/Menu.css';
import "react-datepicker/dist/react-datepicker.css";


export class Layout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            questionToShow: 1000,
            answerToShow: 1000,
            dropDownToShow: null,
            displayLanguage: "Svenska",
            LanguageID: 1,
            displayHeader: "Sadima",
            MenuItems: [],
            SwedishQuestionItems: [],
            Languages: [],
            TranslationToShow: [],
            QuestionType: "PXQEmpty",
            ChildrenToChosenMenu: [],
            SweAnswerItems: [],
            ArabAnswerItems: [],
            EnglishAnswerItems: [],
            AnswerItems: [],
            AudioToBePlayed: new Audio(),
            AudioHasEnded: false,
            VideoToBePlayed: "",
            startDate: new Date(),
            QuestionObject: [],
            ButtonAudio: new Audio(),
            SweCountries: [],
            ArabCountries: [],
            EngCountries: [],
            SweSpokenLanguages: [],
            ArabSpokenLanguages: [],
            EngSpokenLanguages: [],
            someState: 0,
            baseUrl: "https://sadima.lnu.se/api/"
        };
        this.handleQuestionChange = this.handleQuestionChange.bind(this);
        this.handleAnswerChange = this.handleAnswerChange.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.handleDropdownSelection = this.handleDropdownSelection.bind(this);
        this.handlePlaySound = this.handlePlaySound.bind(this);
        this.playInstructionVideo = this.playInstructionVideo.bind(this);

    }


    /* Hanterar state för languagechange och gör det möjligt att ha olika svar-värden beroende på vilket språk som är satt */
    async handleLanguageChange() {

        console.log(this.state.displayLanguage);

        var temp = this.state.displayLanguage;

        await this.state.Languages.map((props) => {

            if (props.id === this.state.LanguageID + 1) {

                this.setState({
                    ...this.state,
                    displayLanguage: props.languageText,
                    LanguageID: props.id
                })
            }

        });

        if (temp === this.state.displayLanguage) {

            this.setState({
                ...this.state,
                displayLanguage: "Svenska",
                LanguageID: 1
            });
        }

        //this.state.displayLanguage === "Svenska" ? await this.setState({ ...this.state, displayLanguage: "Arabiska", LanguageID: 2 }) : await this.setState({ ...this.state, displayLanguage: "Svenska", LanguageID: 1 });

        await fetch(this.state.baseUrl + 'GetQuestionOnQuestionAndLanguageID/' + this.state.answerToShow + '/' + this.state.LanguageID).then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                TranslationToShow: data
            }));
    }

    /* Hanterar vilket svar vi ska visa baserat på ID:t av elementet vi har klickat på */
    async handleAnswerChange(id, audioUrl, videoUrl) {

        await fetch(this.state.baseUrl + 'GetQuestionTypeOnQuestionID/' + id).then(response => response.text()).then(data =>
            this.setState({
                ...this.state,
                QuestionType: data
            }));

        this.setState({
            ...this.state,
            answerToShow: id,
            AudioHasEnded: false
        });

        await fetch(this.state.baseUrl + 'GetQuestionOnQuestionAndLanguageID/' + id + '/' + this.state.LanguageID).then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                QuestionObject: data
            }));



        if (this.state.QuestionType !== "PXQVideo") {

            var audio = document.getElementById("audio");
            audio.src = this.state.QuestionObject[0].audioUrl;

            var audioPromise = audio.play();

            if (audioPromise !== undefined) {
                audioPromise.then(x => {
                    console.log("audio played auto");
                })
                    .catch(error => {
                        console.log("didnt play audio");
                    });
            }

            audio.onended = () => {
                this.setState({ ...this.state, AudioHasEnded: true, AudioToBePlayed: new Audio() })
            }
        }
        else {
            this.setState({
                ...this.state,
                VideoToBePlayed: videoUrl
            })

            var video = document.getElementById("video");
            video.src = this.state.QuestionObject[0].videoUrl;
        }



        fetch(this.state.baseUrl + 'GetQuestionOnQuestionAndLanguageID/' + id + '/' + this.state.LanguageID).then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                TranslationToShow: data
            }));

        await fetch(this.state.baseUrl + 'GetAllAnswersOnQuestionAndLanguageID/' + id + '/' + 1).then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                SweAnswerItems: data
            }));

        await fetch(this.state.baseUrl + 'GetAllAnswersOnQuestionAndLanguageID/' + id + '/' + 2).then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                ArabAnswerItems: data
            }));

        await fetch(this.state.baseUrl + 'GetAllAnswersOnQuestionAndLanguageID/' + id + '/' + 3).then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                EnglishAnswerItems: data
            }));

        console.log(this.state.SweAnswerItems);

        var temp = [];

        for (var i = 0; i < this.state.SweAnswerItems.length; i++) {
            temp.push({
                "swe": this.state.SweAnswerItems[i],
                "arab": this.state.ArabAnswerItems[i],
                "eng": this.state.EnglishAnswerItems[i]
            })
        }

        await this.setState({
            ...this.state,
            AnswerItems: temp
        });
    }

    /* Hanterar vilken fråga samt header vi ska visa baserat på vilket menyval som har klickats på, återställer också svaren om vi byter meny */
    handleQuestionChange(id, statement) {

        console.log(this.state.ArabCountries);

        if (document.getElementById(this.state.questionToShow) !== null) {
            document.getElementById(this.state.questionToShow).style.backgroundColor = 'white';
            document.getElementById(this.state.questionToShow).style.color = 'black';
        }

        if (window.innerWidth < 900) {
            var menu = document.getElementById("menu");
            var main = document.getElementById("main");

            menu.style.display = "none";
            main.style.display = "block";
        }

        this.setState({
            ...this.state,
            questionToShow: id,
            answerToShow: 1000,
            displayHeader: statement,
            TranslationToShow: [],
            QuestionType: "",
            AnswerItems: [],
            ChildrenToChosenMenu: []
        });

        fetch(this.state.baseUrl + 'getQuestion/' + id + '/1').then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                SwedishQuestionItems: data
            }));

        fetch(this.state.baseUrl + 'getChildren/' + id).then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                ChildrenToChosenMenu: data
            }));

        document.getElementById(id).style.backgroundColor = 'black';
        document.getElementById(id).style.color = 'white';

    }

    async handlePlaySound(sweAudio, arabAudio, engAudio) {

        console.log("Inne i handlePlaySound");

        await this.state.ButtonAudio.pause();

        this.setState({
            ...this.state,
            ButtonAudio: new Audio()
        });

        console.log(sweAudio);

        if (this.state.LanguageID == 1) {
            this.state.ButtonAudio.src = sweAudio;
        }
        else if (this.state.LanguageID == 2) {
            this.state.ButtonAudio.src = arabAudio;
        }
        else {
            this.state.ButtonAudio.src = engAudio;
        }

        var audioPromise = this.state.ButtonAudio.play();

        if (audioPromise !== undefined) {
            audioPromise.then(x => {
                console.log("audio played auto");
            })
                .catch(error => {
                    console.log("didnt play audio");
                });
        }

        this.state.ButtonAudio.onended = () => {
            this.setState({ ...this.state, ButtonAudio: new Audio() })
        }

        if (sweAudio === "https://sadima.lnu.se/Media/Ljudfiler/Tyst.mp3") {
            var audio = document.getElementById("audio");
            audio.src = sweAudio;
            audio.play();
        }



    }

    /* Hanterar val av dropdown. Den valda dropdownens children visas. Alla andra dropdowns children döljs. */
    handleDropdownSelection(id) {
        if (id === this.state.shownDropdownId) {
            this.setState({
                ...this.state,
                shownDropdownId: 1000
            });
        }
        else {
            this.setState({
                ...this.state,
                shownDropdownId: id
            });
        }
    }

    async playInstructionVideo() {

        if (document.getElementById(this.state.questionToShow) !== null) {
            document.getElementById(this.state.questionToShow).style.backgroundColor = 'white';
            document.getElementById(this.state.questionToShow).style.color = 'black';
        }

        await this.setState({
            ...this.state,
            questionToShow: "",
            answerToShow: 1000,
            displayHeader: "Instruktioner",
            TranslationToShow: [],
            QuestionType: "",
            AnswerItems: [],
            ChildrenToChosenMenu: [],
            SwedishQuestionItems: []
        });

        this.setState({
            ...this.state,
            QuestionType: "PXQVideo",
            VideoToBePlayed: "https://sadima.lnu.se/Media/Filmer/Instruktioner.mp4"
        })

        var video = document.getElementById("video");
        video.src = "https://sadima.lnu.se/Media/Filmer/Instruktioner.mp4" ;
    }

    componentDidMount() {

        fetch(this.state.baseUrl + 'GetMenus', { headers: {'Access-Control-Allow-Origin': '*'} }).then(response => response.json()).then(data =>
            this.setState({
                    ...this.state,
                    MenuItems: data
                }));

        fetch(this.state.baseUrl + 'GetAllLanguages').then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                Languages: data
            }));

        fetch(this.state.baseUrl + 'GetAllCountriesOnLanguageID/1').then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                SweCountries: data
            }));

        fetch(this.state.baseUrl + 'GetAllCountriesOnLanguageID/2').then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                ArabCountries: data
            }));

        fetch(this.state.baseUrl + 'GetAllCountriesOnLanguageID/3').then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                EngCountries: data
            }));

        fetch(this.state.baseUrl + 'GetAllLanguagesOnLanguageID/1').then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                SweSpokenLanguages: data
            }));

        fetch(this.state.baseUrl + 'GetAllLanguagesOnLanguageID/2').then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                ArabSpokenLanguages: data
            }));

        fetch(this.state.baseUrl + 'GetAllLanguagesOnLanguageID/3').then(response => response.json()).then(data =>
            this.setState({
                ...this.state,
                EngSpokenLanguages: data
            }));

    }





    /* Det mesta här ska inhämtas från databasen sedan utan att skrivas upp manuellt*/
    render() {

        console.log(this.state.AudioToBePlayed);

        const isDropdownMenu = this.state.ChildrenToChosenMenu.length !== 0;
        const isVideo = this.state.QuestionType === "PXQVideo";
        const isMultiChoice = this.state.QuestionType === "PXQMultiButton"; // Skillnad på MultiChoice och MultiSelect är att på multiselect ska man kunna markera fler än 1 val.
        const isMultiSelect = this.state.QuestionType === "PXQMultiSelect";
        const isInput = this.state.QuestionType === "PXQLetters" || this.state.QuestionType === "PXQNumbers" || this.state.QuestionType === "PXQYears";
        const isCalendar = this.state.QuestionType === "PXQCalendar";
        const isYesNo = this.state.QuestionType === "PXQYesNo";
        const isCountries = this.state.QuestionType === "PXQCountries";
        const isLanguages = this.state.QuestionType === "PXQLanguages";
        const isYesNoCountries = this.state.QuestionType === "PXQYesNoCountries";
        const isYesNoMulti = this.state.QuestionType === "PXQYesNoMulti";
        const isEmpty = this.state.QuestionType === "PXQEmpty";



        return (

            <div className="sadima">

                <div className="row no-gutters grey-background small-height">
                    <div className="sadimatext col-2">
                        <LeftHeader />
                        <audio id="audio" className="audio-container" src="" />
                    </div>

                    <div className="small-navmenu col-2">
                        <Button variant="secondary" size="sm" onClick={() => {
                            var menu = document.getElementById("menu");
                            var main = document.getElementById("main");

                            if (menu.style.display === "none") {
                                menu.style.display = "block";
                                menu.style.width = "100%";
                                main.style.display = "none";
                            }
                            else {
                                menu.style.display = "none";
                                main.style.display = "block";
                            }

                        }}> Meny </Button>
                    </div>

                    <div className="col-10">
                        <RightHeader statement={this.state.displayHeader} />
                    </div>

                </div>

                <div id="menu" className="menu">
                    <div className="top-container">
                    <div className="static-statements lnu-background showLanguageText" style={{ display: "flex" }} onClick={this.handleLanguageChange}>
                        Patientens språk <div className="chooseLanguageText">{this.state.displayLanguage} </div>
                    </div>

                        <div className="nav-link Statements" onClick={this.playInstructionVideo} style={{ fontWeight: "500" }}> Instruktionsfilm <ChevronLeft /></div>
                       </div>

                    {this.state.MenuItems.map((props) => (
                        props.menuChildren.length !== 0 ?
                            <div onClick={() => this.handlePlaySound("https://sadima.lnu.se/Media/Ljudfiler/Tyst.mp3", "https://sadima.lnu.se/Media/Ljudfiler/Tyst.mp3")}>
                                <div className="static-statements grey-background" >
                                    {props.menuText}
                                </div>

                                {props.menuChildren.map((props) => (
                                    <InformationItem
                                        statement={props.menuText}
                                        itemID={props.id}
                                        onQuestionChange={this.handleQuestionChange}
                                    ></InformationItem>
                                ))}
                            </div>

                            :

                            <InformationItem
                                statement={props.menuText}
                                itemID={props.id}
                                onQuestionChange={this.handleQuestionChange}
                            ></InformationItem>
                    ))}
                </div>

                <div id="main" className="main">
                    {this.state.questionToShow != 1000 ?
                        <div className="row-5 half-window-top make-scrollable add-border goback">
                            {isDropdownMenu ?
                                <div>
                                    {this.state.ChildrenToChosenMenu.map((props) => (
                                        <Dropdown
                                            shownDropdownId={this.state.shownDropdownId}
                                            handleDropdownSelection={this.handleDropdownSelection}
                                            statement={props.menuText}
                                            itemID={props.id}
                                            onAnswerChange={this.handleAnswerChange}
                                        ></Dropdown>
                                    ))}
                                </div>
                                :
                                <div>
                                    {this.state.SwedishQuestionItems.map((props) => (
                                        <Question
                                            itemID={props.id}
                                            question={props.textUrl}
                                            audio={props.audioUrl}
                                            video={props.videoUrl}
                                            onAnswerChange={this.handleAnswerChange}
                                        ></Question>
                                    ))}
                                </div>}

                        </div>
                        :
                        <div className="row-5 half-window-top make-scrollable add-border goback" style={{ paddingLeft: "10px" }}>
                            <p><h3>Välkommen till Sadima, språkstöd till mödrahälsovård!</h3>
                                <p>Denna webbsida har skapats som ett dialogstöd åt svenska barnmorskor som behöver samtala med kvinnor som har begränsade kunskaper i svenska. Stödet finns på tre språk – arabiska, engelska och svenska. Stödet är ett komplement till en tolk.</p>
                                <p>Syftet är att ge rätt information till rätt person vid rätt tidpunkt. Sadima innehåller enbart evidensbaserad och kvalitetssäkrad information och har utvecklats i samarbete mellan med forskare vid Linnéuniversitetet och barnmorskor som arbetar i mödravården i region Kalmar län.</p>
                                <p> Projektet har finansierats av Vinnova och Familjen Kamprads stiftelse. </p>
                            
                                <p>Klicka på Svenska i menyn till vänster för att byta språk.</p> 

                                <p>Klicka <a href="https://lnu.se/forskning/sok-forskning/forskningsprojekt/projekt-vanta-barn-pa-arabiska-och-svenska-effektiv-och-integrerande-design-for-interaktiv-modrahalsovard/">här</a> för mer information</p>


                            <p><h3>Welcome to Sadima, language support in antenatal care!</h3>
                                   <p>This website was created to assist Swedish midwives in antenatal care when they need to communicate with women who have a limited knowledge of Swedish. There are three languages – Arabic, English and Swedish. Sadima serves as a complement to an interpreter.</p> 
                                    <p>The aim is to give the right information to the right person at the right time. Sadima contains only evidence-based, quality-assured information and was developed in collaboration between researchers at Linnaeus University and midwives who work in antenatal care, in Kalmar County, Sweden.</p>
                                    <p>The project was funded by Vinnova and the Kamprad Family Foundation.</p>
                            
                                <p>Click on Svenska in the menu to the left to change language.</p>
                                
                                    <p>Click <a href="https://lnu.se/forskning/sok-forskning/forskningsprojekt/projekt-vanta-barn-pa-arabiska-och-svenska-effektiv-och-integrerande-design-for-interaktiv-modrahalsovard/"> here </a>for more information </p>
                                </p>
                            </p>
                        </div>
                        }




                    <div className={`tranwindow row half-window-bottom make-scrollable ${isVideo && 'content-center'}`}>

                        {isVideo ?
                            <div>
                                <video id="video" controls autoPlay className="video-container" src="" >
                                </video>
                            </div>
                            :
                            <></>
                        }

                        {isMultiChoice ?
                            <div className="col-6 answerbox-left multibox">

                                {this.state.AudioHasEnded ?

                                    <div className="buttonContainer">
                                        {this.state.AnswerItems.map((props) => (
                                            <Button className="multichoice-btn" variant="outline-secondary" size="lg" style={{ borderWidth: "medium" }} onClick={() => this.handlePlaySound(props.swe.answerAudio, props.arab.answerAudio, props.eng.answerAudio)}>
                                                <div className="textContainer">
                                                    {this.state.LanguageID === 1 ?
                                                        <div className="col" style={{ fontWeight: "800", color: "black" }}>{props.swe.answerText}</div>
                                                        :
                                                    <>
                                                        {
                                                                this.state.LanguageID == 2 ?
                                                                <>
                                                                    <div className="col" style={{ fontWeight: "800", color: "black" }}>{props.swe.answerText}</div>
                                                                    <div className="col" style={{ fontWeight: "800", color: "black" }}>{props.arab.answerText}</div>
                                                                </>
                                                                    :
                                                                <>
                                                                    <div className="col" style={{ fontWeight: "800", color: "black" }}>{props.swe.answerText}</div>
                                                                    <div className="col" style={{ fontWeight: "800", color: "black" }}>{props.eng.answerText}</div>
                                                                </>
                                                            }
                                                    </>
                                                        }
                                                </div>
                                            </Button>
                                        ))}
                                    </div>
                                    :
                                    <div className="col-6 answerbox"></div>
                                }
                            </div>
                            :
                            <></>
                        }

                        {isMultiSelect ?
                            <div className="col-6 answerbox-left multibox">

                                {this.state.AudioHasEnded ?
                                    <div className="buttonContainer">
                                        {this.state.AnswerItems.map((props) => (
                                            <Button className="multichoice-btn" variant="outline-secondary" size="lg" style={{ borderWidth: "medium" }} onClick={() => this.handlePlaySound(props.swe.answerAudio, props.arab.answerAudio, props.eng.answerAudio)}>
                                                <div className="textContainer">
                                                    {this.state.LanguageID === 1 ?
                                                        <div className="col" style={{ fontWeight: "800", color: "black" }}>{props.swe.answerText}</div>
                                                        :
                                                        <>
                                                            {
                                                                this.state.LanguageID == 2 ?
                                                                    <>
                                                                        <div className="col" style={{ fontWeight: "800", color: "black" }}>{props.swe.answerText}</div>
                                                                        <div className="col" style={{ fontWeight: "800", color: "black" }}>{props.arab.answerText}</div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className="col" style={{ fontWeight: "800", color: "black" }}>{props.swe.answerText}</div>
                                                                        <div className="col" style={{ fontWeight: "800", color: "black" }}>{props.eng.answerText}</div>
                                                                    </>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </Button>
                                        ))}

                                    </div>
                                    :
                                    <div className="col-6 answerbox"></div>
                                }
                            </div>
                            :
                            <></>
                        }

                        {isCalendar ?
                            <div className="col-6 answerbox calendarBox" style={{ alignItems: "normal", paddingTop: "30px" }}>

                                {this.state.AudioHasEnded ?
                                    <div className="calendarContainer">
                                        <DatePicker selected={this.state.startDate} dateFormat="yyyy-MM-dd" onChange={date => this.setState({ startDate: date })} />
                                    </div>
                                    :
                                    <div className="col-6 answerbox"></div>
                                }
                            </div>
                            :
                            <></>
                        }

                        {isInput ?
                            <div className="col-6 answerbox">

                                {this.state.AudioHasEnded ?

                                    <input className="inputfield" autoFocus type="text" />

                                    :
                                    <div className="col-6 answerbox"></div>
                                }
                            </div>
                            :
                            <></>
                        }

                        {isYesNo ?
                            <div className="col-6 answerbox">

                                {this.state.AudioHasEnded ?
                                    <div className="buttonContainer">
                                        <ButtonToolbar className="yesno">

                                            <Button className="yesno d-flex justify-content-center d-md-table mx-auto" variant="outline-success" size="lg" style={{ borderWidth: "medium" }} onClick={() => this.handlePlaySound("https://sadima.lnu.se/Media/Ljudfiler/a1_yes_sw.mp3", "https://sadima.lnu.se/Media/Ljudfiler/a1_yes_ar.mp3", "https://sadima.lnu.se/Media/Ljudfiler/a1_yes_en.mp3")}>
                                                {this.state.LanguageID === 1 ?
                                                    <div style={{ fontWeight: "800" }}> Ja </div>
                                                    :
                                                    <>
                                                        {
                                                            this.state.LanguageID == 2 ?
                                                                <>
                                                                    <div style={{ fontWeight: "800" }}> Ja </div>
                                                                    <div style={{ fontWeight: "800" }}> نعم </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div style={{ fontWeight: "800" }}> Ja </div>
                                                                    <div style={{ fontWeight: "800" }}> Yes </div>
                                                                </>
                                                        }
                                                    </>
                                                }
                                            </Button>
                                            &nbsp;
                                            <Button className="yesno d-flex justify-content-center d-md-table mx-auto" variant="outline-danger" size="lg" style={{ borderWidth: "medium" }} onClick={() => this.handlePlaySound("https://sadima.lnu.se/Media/Ljudfiler/a2_no_sw.mp3", "https://sadima.lnu.se/Media/Ljudfiler/a2_no_ar.mp3", "https://sadima.lnu.se/Media/Ljudfiler/a2_no_en.mp3")}>
                                                {this.state.LanguageID === 1 ?
                                                    <div style={{ fontWeight: "800" }}> Nej </div>
                                                    :
                                                    <>
                                                        {
                                                            this.state.LanguageID == 2 ?
                                                                <>
                                                                    <div style={{ fontWeight: "800" }}> Nej </div>
                                                                    <div style={{ fontWeight: "800" }}> لا </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div style={{ fontWeight: "800" }}> Nej </div>
                                                                    <div style={{ fontWeight: "800" }}> No </div>
                                                                </>
                                                        }
                                                    </>
                                                }
                                            </Button>

                                        </ButtonToolbar>
                                    </div>
                                    :
                                    <div className="col-6 answerbox"></div>
                                }
                            </div>
                            :
                            <></>
                        }

                        {isYesNoCountries ?
                            <div className="col-6 answerbox">

                                {this.state.AudioHasEnded ?
                                    <div>
                                        {this.state.LanguageID === 1 ?
                                            <div>
                                                <div className="buttonContainer">
                                                    <ButtonToolbar className="yesno">

                                                        <Button className="yesno d-flex justify-content-center d-md-table mx-auto" variant="outline-success" size="lg" style={{ borderWidth: "medium" }} onClick={() => this.handlePlaySound("https://sadima.lnu.se/Media/Ljudfiler/a1_yes_sw.mp3", "https://sadima.lnu.se/Media/Ljudfiler/a1_yes_ar.mp3", "https://sadima.lnu.se/Media/Ljudfiler/a1_yes_en.mp3")}>
                                                            {this.state.LanguageID === 1 ?
                                                                <div style={{ fontWeight: "800" }}> Ja </div>
                                                                :
                                                                <>
                                                                    {
                                                                        this.state.LanguageID == 2 ?
                                                                            <>
                                                                                <div style={{ fontWeight: "800" }}> Ja </div>
                                                                                <div style={{ fontWeight: "800" }}> نعم </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <div style={{ fontWeight: "800" }}> Ja </div>
                                                                                <div style={{ fontWeight: "800" }}> Yes </div>
                                                                            </>
                                                                    }
                                                                </>
                                                            }
                                                        </Button>
                                                        &nbsp;
                                                        <Button className="yesno d-flex justify-content-center d-md-table mx-auto" variant="outline-danger" size="lg" style={{ borderWidth: "medium" }} onClick={() => this.handlePlaySound("https://sadima.lnu.se/Media/Ljudfiler/a2_no_sw.mp3", "https://sadima.lnu.se/Media/Ljudfiler/a2_no_ar.mp3", "https://sadima.lnu.se/Media/Ljudfiler/a2_no_en.mp3")}>
                                                            {this.state.LanguageID === 1 ?
                                                                <div style={{ fontWeight: "800" }}> Nej </div>
                                                                :
                                                                <>
                                                                    {
                                                                        this.state.LanguageID == 2 ?
                                                                            <>
                                                                                <div style={{ fontWeight: "800" }}> Nej </div>
                                                                                <div style={{ fontWeight: "800" }}> لا </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <div style={{ fontWeight: "800" }}> Nej </div>
                                                                                <div style={{ fontWeight: "800" }}> No </div>
                                                                            </>
                                                                    }
                                                                </>
                                                            }
                                                        </Button>

                                                    </ButtonToolbar>
                                                </div>
                                                <select>
                                                    {this.state.SweCountries.map((props) => (
                                                        <option value={props.countryName}>{props.countryName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            :
                                            <>
                                                {
                                                    this.state.LanguageID == 2 ?
                                                        <div>
                                                            <div className="buttonContainer">
                                                                <ButtonToolbar className="yesno">

                                                                    <Button className="yesno d-flex justify-content-center d-md-table mx-auto" variant="outline-success" size="lg" style={{ borderWidth: "medium" }} onClick={() => this.handlePlaySound("https://sadima.lnu.se/Media/Ljudfiler/a1_yes_sw.mp3", "https://sadima.lnu.se/Media/Ljudfiler/a1_yes_ar.mp3", "https://sadima.lnu.se/Media/Ljudfiler/a1_yes_en.mp3")}>
                                                                        {this.state.LanguageID === 1 ?
                                                                            <div style={{ fontWeight: "800" }}> Ja </div>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    this.state.LanguageID == 2 ?
                                                                                        <>
                                                                                            <div style={{ fontWeight: "800" }}> Ja </div>
                                                                                            <div style={{ fontWeight: "800" }}> نعم </div>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <div style={{ fontWeight: "800" }}> Ja </div>
                                                                                            <div style={{ fontWeight: "800" }}> Yes </div>
                                                                                        </>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </Button>
                                                        &nbsp;
                                                        <Button className="yesno d-flex justify-content-center d-md-table mx-auto" variant="outline-danger" size="lg" style={{ borderWidth: "medium" }} onClick={() => this.handlePlaySound("https://sadima.lnu.se/Media/Ljudfiler/a2_no_sw.mp3", "https://sadima.lnu.se/Media/Ljudfiler/a2_no_ar.mp3", "https://sadima.lnu.se/Media/Ljudfiler/a2_no_en.mp3")}>
                                                                        {this.state.LanguageID === 1 ?
                                                                            <div style={{ fontWeight: "800" }}> Nej </div>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    this.state.LanguageID == 2 ?
                                                                                        <>
                                                                                            <div style={{ fontWeight: "800" }}> Nej </div>
                                                                                            <div style={{ fontWeight: "800" }}> لا </div>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <div style={{ fontWeight: "800" }}> Nej </div>
                                                                                            <div style={{ fontWeight: "800" }}> No </div>
                                                                                        </>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </Button>

                                                                </ButtonToolbar>
                                                            </div>
                                                            <select>
                                                                {this.state.ArabCountries.map((props) => (
                                                                    <option value={props.countryName}>{props.countryName}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        :
                                                        <div>
                                                            <div className="buttonContainer">
                                                                <ButtonToolbar className="yesno">

                                                                    <Button className="yesno d-flex justify-content-center d-md-table mx-auto" variant="outline-success" size="lg" style={{ borderWidth: "medium" }} onClick={() => this.handlePlaySound("https://sadima.lnu.se/Media/Ljudfiler/a1_yes_sw.mp3", "https://sadima.lnu.se/Media/Ljudfiler/a1_yes_ar.mp3", "https://sadima.lnu.se/Media/Ljudfiler/a1_yes_en.mp3")}>
                                                                        {this.state.LanguageID === 1 ?
                                                                            <div style={{ fontWeight: "800" }}> Ja </div>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    this.state.LanguageID == 2 ?
                                                                                        <>
                                                                                            <div style={{ fontWeight: "800" }}> Ja </div>
                                                                                            <div style={{ fontWeight: "800" }}> نعم </div>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <div style={{ fontWeight: "800" }}> Ja </div>
                                                                                            <div style={{ fontWeight: "800" }}> Yes </div>
                                                                                        </>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </Button>
                                                        &nbsp;
                                                        <Button className="yesno d-flex justify-content-center d-md-table mx-auto" variant="outline-danger" size="lg" style={{ borderWidth: "medium" }} onClick={() => this.handlePlaySound("https://sadima.lnu.se/Media/Ljudfiler/a2_no_sw.mp3", "https://sadima.lnu.se/Media/Ljudfiler/a2_no_ar.mp3", "https://sadima.lnu.se/Media/Ljudfiler/a2_no_en.mp3")}>
                                                                        {this.state.LanguageID === 1 ?
                                                                            <div style={{ fontWeight: "800" }}> Nej </div>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    this.state.LanguageID == 2 ?
                                                                                        <>
                                                                                            <div style={{ fontWeight: "800" }}> Nej </div>
                                                                                            <div style={{ fontWeight: "800" }}> لا </div>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <div style={{ fontWeight: "800" }}> Nej </div>
                                                                                            <div style={{ fontWeight: "800" }}> No </div>
                                                                                        </>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </Button>

                                                                </ButtonToolbar>
                                                            </div>
                                                            <select>
                                                                {this.state.EngCountries.map((props) => (
                                                                    <option value={props.countryName}>{props.countryName}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                }
                                            </>
                                            
                                            
                                        }
                                    </div>
                                    :
                                    <div className="col-6 answerbox"></div>
                                }
                            </div>
                            :
                            <></>
                        }

                        {isYesNoMulti ?
                            <div className="col-6 answerbox-left multibox">

                                {this.state.AudioHasEnded ?
                                    <div className="buttonContainer">
                                        <ButtonToolbar className="yesno">

                                            <Button className="yesno d-flex justify-content-center d-md-table mx-auto" variant="outline-success" size="lg" onClick={() => this.handlePlaySound("https://sadima.lnu.se/Media/Ljudfiler/a1_yes_sw.mp3", "https://sadima.lnu.se/Media/Ljudfiler/a1_yes_ar.mp3", "https://sadima.lnu.se/Media/Ljudfiler/a1_yes_en.mp3")}>
                                                {this.state.LanguageID === 1 ?
                                                    <div style={{ fontWeight: "800" }}> Ja </div>
                                                    :
                                                    <>
                                                        {
                                                            this.state.LanguageID == 2 ?
                                                                <>
                                                                    <div style={{ fontWeight: "800" }}> Ja </div>
                                                                    <div style={{ fontWeight: "800" }}> نعم </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div style={{ fontWeight: "800" }}> Ja </div>
                                                                    <div style={{ fontWeight: "800" }}> Yes </div>
                                                                </>
                                                        }
                                                    </>
                                                }
                                            </Button>
                                            &nbsp;
                                            <Button className="yesno d-flex justify-content-center d-md-table mx-auto" variant="outline-danger" size="lg" onClick={() => this.handlePlaySound("https://sadima.lnu.se/Media/Ljudfiler/a2_no_sw.mp3", "https://sadima.lnu.se/Media/Ljudfiler/a2_no_ar.mp3", "https://sadima.lnu.se/Media/Ljudfiler/a2_no_en.mp3")}>
                                                {this.state.LanguageID === 1 ?
                                                    <div style={{ fontWeight: "800" }}> Nej </div>
                                                    :
                                                    <>
                                                        {
                                                            this.state.LanguageID == 2 ?
                                                                <>
                                                                    <div style={{ fontWeight: "800" }}> Nej </div>
                                                                    <div style={{ fontWeight: "800" }}> لا </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div style={{ fontWeight: "800" }}> Nej </div>
                                                                    <div style={{ fontWeight: "800" }}> No </div>
                                                                </>
                                                        }
                                                    </>
                                                }
                                            </Button>

                                        </ButtonToolbar>
                                        {this.state.AnswerItems.map((props) => (
                                            <Button className="multichoice-btn" variant="outline-secondary" size="lg" style={{ borderWidth: "medium" }} onClick={() => this.handlePlaySound(props.swe.answerAudio, props.arab.answerAudio, props.eng.answerAudio)}>
                                                <div className="textContainer">
                                                    {this.state.LanguageID === 1 ?
                                                        <div className="col" style={{ fontWeight: "800", color: "black" }}>{props.swe.answerText}</div>
                                                        :
                                                        <>
                                                            {
                                                                this.state.LanguageID == 2 ?
                                                                    <>
                                                                        <div className="col" style={{ fontWeight: "800", color: "black" }}>{props.swe.answerText}</div>
                                                                        <div className="col" style={{ fontWeight: "800", color: "black" }}>{props.arab.answerText}</div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className="col" style={{ fontWeight: "800", color: "black" }}>{props.swe.answerText}</div>
                                                                        <div className="col" style={{ fontWeight: "800", color: "black" }}>{props.eng.answerText}</div>
                                                                    </>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </Button>
                                        ))}

                                    </div>
                                    :
                                    <div className="col-6 answerbox"></div>
                                }
                            </div>
                            :
                            <></>
                        }

                        {isCountries ?
                            <div className="col-6 answerbox">

                                {this.state.AudioHasEnded ?
                                    <div>
                                        {this.state.LanguageID === 1 ?
                                            <select className="select-css">
                                                {this.state.SweCountries.map((props) => {
                                                    return props.countryName === "Irak" ?
                                                        <>
                                                            <option value={props.countryName}>{props.countryName}</option>
                                                            <option value="-----------">--------------</option>
                                                        </> 
                                                        :
                                                        <option value={props.countryName}>{props.countryName}</option>
                                                })}
                                            </select>
                                            :
                                            <>
                                                {this.state.LanguageID == 2 ? 
                                                    <select className="select-css">
                                                        {this.state.ArabCountries.map((props) => {
                                                            return props.countryName === "العراق" ?
                                                                <>
                                                                    <option value={props.countryName}>{props.countryName}</option>
                                                                    <option value="-----------">--------------</option>
                                                                </>
                                                                :
                                                                <option value={props.countryName}>{props.countryName}</option>
                                                        })}
                                                    </select>
                                                    :
                                                    <select className="select-css">
                                                        {this.state.EngCountries.map((props) => {
                                                            return props.countryName === "Iraq" ?
                                                                <>
                                                                    <option value={props.countryName}>{props.countryName}</option>
                                                                    <option value="-----------">--------------</option>
                                                                </>
                                                                :
                                                                <option value={props.countryName}>{props.countryName}</option>
                                                        })}
                                                    </select>

                                            }
                                            </>
                                        }
                                    </div>
                                    :
                                    <div className="col-6 answerbox"></div>
                                }
                            </div>
                            :
                            <></>
                        }

                        {isLanguages ?
                            <div className="col-6 answerbox">

                                {this.state.AudioHasEnded ?
                                    <div>
                                        {this.state.LanguageID === 1 ?
                                            <select className="select-css">
                                                {this.state.SweSpokenLanguages.map((props) => (
                                                    <option value={props.spokenLanguageText}>{props.spokenLanguageText}</option>
                                                ))}
                                            </select>
                                            :
                                            <>
                                                {this.state.LanguageID == 2 ? 
                                                    <select className="select-css">
                                                        {this.state.ArabSpokenLanguages.map((props) => (
                                                            <option value={props.spokenLanguageText}>{props.spokenLanguageText}</option>
                                                        ))}
                                                    </select>
                                                    :
                                                    <select className="select-css">
                                                        {this.state.EngSpokenLanguages.map((props) => (
                                                            <option value={props.spokenLanguageText}>{props.spokenLanguageText}</option>
                                                        ))}
                                                    </select>
                                                    }
                                            </>
                                        }
                                    </div>
                                    :
                                    <div className="col-6 answerbox"></div>
                                }
                            </div>
                            :
                            <></>
                        }

                        {isEmpty ?
                            <div className="col-6 answerbox">

                            </div>
                            :
                            <></>
                        }


                        {isVideo ?
                            <></>
                            :
                            <div className="col-6 answerbox">
                                {this.state.TranslationToShow.map((props) => (
                                    <div className="answer">
                                        {props.textUrl}
                                    </div>
                                ))
                                }

                            </div>
                        }



                    </div>



                </div>



            </div>

        );
    }
}
