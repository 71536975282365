import React, { Component } from 'react';

export class Question extends Component {

    constructor(props) {
        super(props);
        this.answerChange = this.answerChange.bind(this);
    }

    answerChange(itemID, audioUrl, videoUrl) {
        this.props.onAnswerChange(this.props.itemID, this.props.audio, this.props.video);
    }

    render() {

        const question = this.props.question;

        return (

            <div className="questions" tabIndex="0" onClick={this.answerChange}>{question}</div>

        );
    }

}
