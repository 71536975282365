import React, { Component } from 'react';

import { TrashIcon } from './TrashIcon';


export class AdminQuestion extends Component {

    constructor(props) {
        super(props);
        this.answerChange = this.answerChange.bind(this);
    }

    answerChange(itemID, audioUrl, videoUrl) {
        this.props.onAnswerChange(this.props.itemID, this.props.audio, this.props.video);
    }

    render() {

        const question = this.props.question;

        return (

            <div className="questions" onClick={this.answerChange}>
                {question}
                <button type="button" className="btn btn-default btn-sm" style={{ float: "right" }} onClick={() => this.props.onDelete(this.props.itemID)} title="Ta bort fråga">
                    <TrashIcon />
                </button>
            </div>

        );
    }

}
