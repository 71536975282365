import React, { Component } from 'react';
import { AdminQuestion } from './AdminQuestion';
import { ChevronLeft } from './ChevronLeft';
import { ChevronDown } from './ChevronDown';

import '../../styles/Menu.css';
import { TrashIcon } from './TrashIcon';
import { CreateIcon } from './CreateIcon';

export class AdminDropdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            DropdownQuestions: []
        }

        this.createItem = this.createItem.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
    }

    createItem(id) {
        this.props.onCreate(this.props.itemID);
    }

    deleteItem(id) {
        this.props.onDelete(this.props.itemID);
    }

  
    componentDidMount() {
        /* Hämtar in questions som tillhör respektive dropdownmeny*/
        fetch('https://sadima.lnu.se/api/getQuestion/' + this.props.itemID + '/1').then(response => response.json()).then(data =>
            this.setState({
                DropdownQuestions: data
            }));
    }


    render() {

        const { statement, shownDropdownId, handleDropdownSelection, itemID } = this.props;

        const isShowing = shownDropdownId === this.props.itemID;

        return (
            <div className={`dropdown-container ${isShowing && 'dropdown-container-showing'}`} style={{ overflowY: "scroll" }}>
                <div className="dropdown lnu-background" onClick={() => handleDropdownSelection(itemID)}>
                    {isShowing ? <ChevronDown /> : <ChevronLeft />}{statement}

                    <button type="button" className="btn btn-default btn-sm" style={{ float: "right" }} onClick={() => { if (window.confirm("Är du säker på att du vill ta bort denna dropdown och alla frågor under denna?")) this.deleteItem(this.props.itemID) }} title="Ta bort dropdownmeny">
                        <TrashIcon />
                    </button>

                    <button type="button" className="btn btn-default btn-sm" style={{ float: "right" }} onClick={() => this.createItem(this.props.itemID)} title="Skapa en fråga till dropdownmenyn">
                        <CreateIcon />
                    </button>
                </div>
                {isShowing &&
                <div className="dropdown-questions">
                    {this.state.DropdownQuestions.map((props) => (
                        <AdminQuestion
                            question={props.textUrl}
                            itemID={props.id}
                            audio={props.audioUrl}
                            video={props.videoUrl}
                            onAnswerChange={this.props.onAnswerChange}
                            onDelete={this.props.onQuestionDelete}
                        ></AdminQuestion>
                    ))}
                    </div>
                }
             </div>
              
            

        );
    }

}
