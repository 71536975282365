import React, { Component } from 'react';
import './NavMenu.css';

export class RightHeader extends Component {



    render() {

        const statement = this.props.statement;

    return (
      
        <div className="right-header" >
            <div className="right-header-text">{statement}</div>
           
         
        </div>
      
    );
  }

}
