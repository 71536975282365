import React, { Component } from 'react';
import './NavMenu.css';

export class LeftHeader extends Component {
 

  render () {
    return (
    
        <div className="left-header">
            <div className="left-header-text">Sadima</div>
        </div>
  
    );
  }

}
