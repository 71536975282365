import React, { Component } from 'react';

export class MenuForm extends Component {

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {

        event.preventDefault();
        const data = new FormData(event.target);

        this.props.onSubmit(data, this.props.itemID);
    }



    render() {

        return (
            <div>
                <form onSubmit={this.handleSubmit} method="post" style={{ width: "500px" }}>
                        <div className="form-group">
                        <label>Menytext</label>
                        <input type="hidden" name="id" type="number" style={{display: "none"}} />
                        <input name="menuText" type="text" className="form-control" id="menutext" placeholder="Skriv rubriken på nya menyn här." />
                        <input type="hidden" name="menuChildren" type="array" value="[]" style={{ display: "none" }} />
                    </div>
                        <button type="submit" className="btn btn-primary">Skapa</button>
                    </form>
                
            </div>

        );
    }

}
