import React, { Component } from 'react';
import { Layout } from './components/Layout';
import { Route, Switch } from "react-router-dom";
import { AdminLoginScreen } from './components/AdminComponents/AdminLoginScreen';
import ReactGA from 'react-ga';
import './custom.css'
import 'bootstrap/dist/css/bootstrap.min.css';


export default class App extends Component {
    static displayName = App.name;
    componentDidMount() {
        ReactGA.initialize('G-M5SXP0P9GW');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }
    render() {
        return (
            <Switch>

                <Route exact path="/sadima-login">
                     <AdminLoginScreen />
                </Route>

                <Route path="/">
                    <Layout />
                </Route>

            </Switch>
        );
    }
}
